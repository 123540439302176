import React from 'react'
import './Plans.css'
import {plansData} from '../../data/plansData'
import tick from '../../assets/whiteTick.png'
import arrow from '../../assets/rightArrow.png'

const Plans = () => {
  return (
    <div className='plans-container' id='plans'>
      <div className='blur plans-blur-1'></div>
      <div className='blur plans-blur-2'></div>
      <div className='programs-header' style={{gap:'2rem'}}>
        <span className='stroke-text'>ready to start</span>
        <span>your journey</span>
        <span className='stroke-text'>now withus</span>
      </div>

      <div className='plans'>
        {plansData.map((plans, i) => (
          <div className='plan' key={i}>
            {plans.icon}
            <span>{plans.name}</span>
            <span>$ {plans.price}</span>
            <div className='features'>
              {plans.features.map((feature,i) => (
                <div className='feature'>
                  <img src={tick} alt='' />
                  <span key={i}>{feature}</span>
                </div>
              ))}
            </div>
            <div>
              <span>See More Benefits</span>
              <img src={arrow} alt='' />
            </div>
            <button className='btn'>Join Now</button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Plans