import './App.css';
import Hero from './components/Hero/Hero';
import Plans from './components/Plans/Plans';
import Programs from './components/Programs/Programs';
import Reasons from './components/Reasons/Reasons';
import Subscribe from './components/Subscribe/Subscribe';
import Testimonals from './components/Testimonals/Testimonals';

function App() {
  return (
    <div className="App">
        <Hero />
        <Programs />
        <Reasons />
        <Plans />
        <Testimonals />
        <Subscribe />
    </div>
  );
}

export default App;
