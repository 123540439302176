import React, { useState } from 'react'
import './Testimonals.css'
import { testimonialsData } from '../../data/testimonialsData'
import RightArrow from '../../assets/rightArrow.png'
import leftArrow from '../../assets/leftArrow.png'
import {motion} from 'framer-motion'

const Testimonals = () => {
  const [selected, setSelected] = useState(0)
  const tLength = testimonialsData.length
  const transition = {type: 'spring', duration: 2}

  return (
    <div className='testimonals'>
      <div className='l-side'>
        <span>Testimonals</span>
        <span className='stroke-text'>WHAT THEY</span>
        <span>SAY ABOUT US</span>
        <span>
          {testimonialsData[selected].review}
        </span>
        <span>
          <span style={{color: 'var(--orange)'}}>
            {testimonialsData[selected].name + ' '}
          </span>
          -  {testimonialsData[selected].status}
        </span>
      </div>

      <div className='r-side'>
        <motion.div
        initial={{opacity: 0, x: -100}}
        transition={{...transition, duration: 2}}
        whileInView={{opacity:1, x: 0}}
        ></motion.div>
        <motion.div
        initial={{opacity: 0, x: 100}}
        transition={{...transition, duration: 2}}
        whileInView={{opacity:1, x: 0}}
        ></motion.div>
        <motion.img src={testimonialsData[selected].image} alt=''
        key={selected}
        initial={{opacity: 0, x: 100}}
        animate={{opacity:1, x:0}}
        exit={{opacity:0, x:-100}}
        transition={transition}
        />

        <div className='arrows'>
          <img onClick={() => {
            selected === 0 ? setSelected(tLength - 1) :
            setSelected((prev) => prev - 1)
          }} src={leftArrow} alt='' />
          <img onClick={() => {
            selected === tLength -1  ? setSelected(0) :
            setSelected((prev) => prev + 1)
          }} src={RightArrow} alt='' />
        </div>
      </div>
    </div>
  )
}

export default Testimonals