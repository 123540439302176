import React, { useRef } from 'react'
import './Subscribe.css'
import emailjs from '@emailjs/browser'

const Subscribe = () => {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_3wgbot8', 'template_bmkce7u', form.current, 'dgBeLW1oV5peGG3kj')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className='Subscribe' id='subscribe'>
      <div className='sub-left'>
      <div></div>
        <div>
          <span className='stroke-text'>READY TO </span>
          <span>LEVEL UP</span>
        </div>
        <div>
          <span>YOUR BODY </span>
          <span className='stroke-text'>WITH US</span>
        </div>
      </div>

      <div className='sub-right'>
        <form ref={form} action='' className='email-container' onSubmit={sendEmail}>
          <input type='email' name='user_email' placeholder='Enter Your Mail Id' />
          <button className='btn btn-j'>Join Now</button>
        </form>
      </div>
    </div>
  )
}

export default Subscribe