import React, {useState} from 'react'
import './Header.css'
import Logo from '../../assets/logo.png'
import Bar from '../../assets/bars.png'
import { Link } from 'react-scroll';


const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false
  const [opended, setOpened] = useState(false)

  return (
    <div className='header'>
      <img className='logo' src={Logo} alt='' />
      {opended === false && mobile === true ? (
        <div style={{backgroundColor: 'var(--appColor)', padding: '0.5rem', borderRadius: '5px'}} onClick={() => setOpened(true)}>
          <img src={Bar} alt='' style={{width: '1.5rem', height: '1.5rem', cursor: 'pointer'}} />
        </div>
      ) : (
        <ul className='header-menu'>
          <li>
            <Link onClick={() => setOpened(false)} to='hero' spy={true} smooth={true}>Home</Link>
          </li>
          <li>
            <Link onClick={() => setOpened(false)} to='programs' spy={true} smooth={true}>
            Programs
            </Link>
          </li>
          <li>
          <Link onClick={() => setOpened(false)} to='reasons' spy={true} smooth={true}>
          Why Us
          </Link>
          </li>
          <li>
          <Link onClick={() => setOpened(false)} to='plans' spy={true} smooth={true}>
            Plans
          </Link>
          </li>
          <li>
            <Link onClick={() => setOpened(false)} to='testimonals' spy={true} smooth={true}>
            Testimonials
            </Link>
          </li>
          <li onClick={() => setOpened(false)}>
          <Link onClick={() => setOpened(false)} to='subscribe' spy={true} smooth={true}>
          Subscribe
          </Link>
          </li>
        </ul>
      )}
    </div>
  )
}

export default Header;