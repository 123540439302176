import React from 'react'
import Header from '../Header/Header';
import './Hero.css'
import Heart from '../../assets/heart.png'
import Heros from '../../assets/hero_image.png'
import Backimage from '../../assets/hero_image_back.png'
import Calories from '../../assets/calories.png'
import {motion} from 'framer-motion'

const Hero = () => {
  const transition = {type: 'spring', duration: 2}
  const mobile = window.innerWidth <= 768 ? true : false;

  return (
    <div className='hero'>
      <div className='blur hero-blur'></div>
      <div className='left-h'>
        <Header />

        <div className='the-best-ad'>
          <motion.div
          initial={{left: mobile ? '198px' : '205px'}}
          whileInView={{left:'8px'}}
          transition={{...transition, type: 'tween'}}
          >
          </motion.div>
          <span>the best fitness club in town</span>
        </div>

        <div className='hero-text'>
          <div>
            <span className='stroke-text'>Shape </span>
            <span>Your</span>
          </div>
          <div>
            <span>Ideal Body</span>
          </div>
          <div className='span'>
            In here we will help you to shape & build your ideal body.
          </div>
        </div>

        <div className='figures'>
          <div>
            <span>+140</span>
            <span>expert coachs</span>
          </div>
          <div>
            <span>+978</span>
            <span>members joined</span>
          </div>
          <div>
            <span>+50</span>
            <span>fitness program</span>
          </div>
        </div>
        
        <div className='hero-buttons'>
            <button className='btn'>Get Started</button>
            <button className='btn'>Learn More</button>
        </div>
      </div>

      <div className='right-h'>
        <div className='btn'>Join Now</div>
        <motion.div className='heart-rate'
        initial={{right: '-1rem'}}
        whileInView={{right: '4rem'}}
        transition={transition}
        >
          <img src={Heart} alt='' />
          <span>Heart Rate</span><span>116 bpm</span>
        </motion.div>

        <img className='hero-image' src={Heros} />
        <motion.img 
        className='hero-image-back' src={Backimage}
        initial={{right: '16rem'}}
        whileInView={{right: '25rem'}}
        transition={transition}
        />

        <div className='calories'>
          <img src={Calories} alt='' />
          <div>
          <span>Calories Burned</span>
          <span>220 kcal</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero;